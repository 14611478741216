import  { useRouter } from "next/router";
import { Fragment, useContext, useEffect } from "react";
import ScreenerService from "./api/screener_service";
const jwt = require("jsonwebtoken");
import getConfig from "next/config";
import dynamic from "next/dynamic";
import { isNullOrUndefined } from "is-what";
import AppContext from "../AppContext";
const { publicRuntimeConfig } = getConfig();

const PopularScan = dynamic(() => import("../components/PopularScan"));

export default function Index(props) {
  const router = useRouter();
  const contextData = useContext(AppContext);
  useEffect(() => {
    if (ScreenerService.checkLogin()) {
      router.push("/crypto-screener");
    }
  }, []);

  return (
    <Fragment>
      <div>
        <div className="flex justify-center items-center">
          <div className="bg-white rounded-[14px] border px-4 pb-3 w-[91%] md:pb-1 pt-2  m-2 sm:w-6/12">
            <div className="relative bg-white header-pattern">
              <section className="flex flex-col justify-center items-center py-12 max-w-4xl text-center md:py-12 container-box">
                <p className="text-4xl font-black text-center text-gray-900 md:text-5xl">
                  Find Trade Ideas in Seconds, not Days!
                </p>
                <p className="mt-5 max-w-2xl leading-normal text-gray-700 md:text-lg">
                  Simple, Yet Powerful Technical Indicator Scanner
                </p>
                <div className="flex flex-col justify-center items-center mt-6 md:mt-8">
                  <button
                    onClick={() => {
                      const token = jwt.sign(
                        { password: "tradetoken", tradetokensecurity: "tradetokensecurity" },
                        publicRuntimeConfig.secret,

                        { expiresIn: 100 }
                      );
                      localStorage.setItem("jwt_access_token", token);
                      router.push("/crypto-screener");
                    }}
                    className="flex flex-row justify-center items-center py-2 px-6 space-x-2 text-sm font-bold text-white bg-black rounded-3xl border-2 border-black-600 shadow-md transition-all duration-200 hover:rounded-xl hover:shadow-lg"
                  >
                    <span>Create Scans Now</span>
                    <svg
                      xmlns="http:/www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
        {
          !isNullOrUndefined(contextData["state"]["popularScanDict"]) &&  !isNullOrUndefined(contextData["state"]["selectedScan"]) && (<PopularScan />)
        }
        <div className="p-4"></div>
      </div>
    </Fragment>
  );
}
